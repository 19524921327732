<template>
  <div class="thank-subscribing">
    <h4 class="thank-subscribing__title">
      {{ $t('subscription.thankForSubscribing') }}
    </h4>

    <p class="thank-subscribing__text">
      {{ $t('subscription.emailWithLink') }}
    </p>

    <p class="thank-subscribing__text">
      {{ $t('subscription.followLink') }}
    </p>
  </div>
</template>

<script setup lang="ts">
const { t: $t } = useI18n();
</script>

<style lang="scss" src="./ThankSubscribing.scss" />
